/* eslint-disable react-hooks/exhaustive-deps */
import React , { useState , useCallback , useEffect } from 'react';
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Select from 'react-select';
import { Link } from 'react-router-dom'

import {  exportOrdersAction, getOrdersFilteredAction, getOrdersListAction } from '../../../actions/ordersActions';
import { useGlobalContext } from '../../context/GlobalContext';

import OrderRow from './Orders_Partials/OrderRow';
import ExportExcel from '../../Partials/ExportExcel';

import searchIcon from "../../../assets/images/icons/search.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

import selectStyle from '../../Partials/selectStyle';

import '../../../styles/List.scss';
import { EXPORT_ORDERS, SCROLL_ON_ORDERS, SET_ORDERS_FILTERS } from '../../../actions/types';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function OrdersList({ setActivePage }) {

  const [ context, dispatch ] = useGlobalContext();
  const {  filters } = context.orders;
  
  const [ exportIsLoading , setExportIsLoading] = useState(null);

  function handleChangeFilters(obj) {
    dispatch({
      type : SET_ORDERS_FILTERS,
      payload : { ...filters, ...obj}
    });
  }

  const getOrdersList = useCallback(() => {
    setActivePage("orders");
     getOrdersListAction(dispatch);
    dispatch({
      type : 'SCROLL_ON_ANIMATIONS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : 0
    });

    dispatch({
      type : 'GET_ORDER',
      payload : null
    });

    dispatch({
      type : 'EXPORT_ORDERS',
      payload : null
    });

  }, [dispatch, setActivePage]);

  useEffect(() => {
    getOrdersList();
  }, []);

  useEffect(() => {
    window.scrollTo(0, context.orders.scrollOrders)
  });

  useEffect(() => {
    let fetch = null
    if (filters) {
      if (filters.search) {
        fetch = setTimeout(() => getOrdersFilteredAction(dispatch, filters), 500);
      } else {
        getOrdersFilteredAction(dispatch, filters);
      }
    }
    return () => clearTimeout(fetch);
  }, [filters])


  useScrollPosition(({ prevPos, currPos }) => {
    var scrollPosition = window.scrollY;
    var documentHeight = document.body.scrollHeight;
    var windowHeight = window.innerHeight;
    if (scrollPosition + windowHeight >= documentHeight) {
      handleChangeFilters({ ...filters, page: filters.page + 1}); 
    }
    dispatch({
      type : SCROLL_ON_ORDERS,
      payload : -currPos.y
    });
  });


  useScrollPosition(({ prevPos, currPos }) => {
    dispatch({
      type : SCROLL_ON_ORDERS,
      payload : -currPos.y
    });
  });

  function renderExportBtn() {
    if (context.orders.exportList && context.orders.exportList.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={context.orders.exportList.fileName}
        dataToExport={context.orders.exportList.dataToExport}
        callBack={() => {
          dispatch({
            type : EXPORT_ORDERS,
            payload : null
          });
          setExportIsLoading();
        }} 
      />
    } else {
      return <IconBtn
        label={"Exporter les commandes"}
        icon="export"
        isLoading={exportIsLoading === "orders"}
        handleClick={() => {
          exportOrdersAction(dispatch);
          setExportIsLoading("orders");
        }}
      />
    }
  }

  
  function renderOrdersList() {
    if (context.orders.ordersList) {
      if (context.orders.ordersList.length > 0) {
        return context.orders.ordersList.map(( order , i) => <OrderRow key={i} order={order}/>);
      } else if (filters.search && context.orders.ordersList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      } else if (filters?.magasins?.length && context.orders.ordersList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      } else if (filters?.fournisseurs?.length && context.orders.ordersList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };;
    };
  };

  function renderSelectProviders() {
    var options = context.orders?.lists?.fournisseurs || [];
    return (
      <Select
        options={_.uniqBy(options, "label")}
        value={options.filter((opt) => filters?.fournisseurs?.find((o) => o === opt.value))}
        onChange={(val) => handleChangeFilters({
          page: 1,
          fournisseurs: val?.map(v => v.value) || []
        })}
        isMulti={true}
        styles={selectStyle}
        placeholder="Filtrer par fournisseur"
        />
    )
  }

  function renderSelectClients() {
    var options = context.orders?.lists?.magasins || [];
    return (
      <Select
        options={_.uniqBy(options, "label")}
        value={options.filter((opt) => filters?.magasins?.find((o) => o === opt.value))}
        onChange={(val) => handleChangeFilters({
          page: 1,
          magasins: val?.map(v => v.value) || []
        })}
        isMulti={true}
        styles={selectStyle}
        placeholder="Filtrer par magasins"
        />
    )
  }


  return (

    <div className="page-container">
      <header className="page-header space-between">
        <h1>Les commandes</h1>
        <div className="btn-container export">
          {renderExportBtn()}
          <Link to={'/create-order'}>
            <button
              className="btn-edit"
              style={{ height:36 , paddingLeft: 20 , paddingRight: 20 }}>
              Créer une commande
            </button>
          </Link>
        </div>
      </header>

      <main className="page-content">

        <div className="row search-container">
          <div className="col-3 input">
            <input
              placeholder="Recherche"
              value={filters.search}
              onChange={(e) => handleChangeFilters({ search: e.target.value, page: 1 })}
            />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
          <div className="col-3 select">
            {renderSelectProviders()}
          </div>
          <div className="col-3 select">
            {renderSelectClients()}
          </div>
        </div>

        <div className="filters">

          <div
            className="filter"
            onClick={() => handleChangeFilters({
              page: 1,
              orderBy: {
                key: "magasinName",
                order: filters.orderBy.order === "asc" ? "desc":"asc"
              }
            })}>
            <span>Magasin</span>
            <img src={filterIcon} className={filters.orderBy.key === 'magasinName' && filters.orderBy.order === "asc"? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-4%)'}}
            onClick={() => handleChangeFilters({
              page: 1,
              orderBy: {
                key: "fournisseurName",
                order: filters.orderBy.order === "asc" ? "desc":"asc"
              }
            })}>
            <span>Fournisseur</span>
            <img src={filterIcon} className={filters.orderBy.key === 'fournisseurName' && filters.orderBy.order === "asc"? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(10%)'}}
          >
            <span>Nombre d’articles</span>
          </div>

          <div
            style={{ transform: 'translateX(-42%)'}}
            className="filter">
            {/* onClick={() => setSortByAlpha(!sortByAlpha)}*/}
            <span>Types de produits</span>
            {/*<img src={filterIcon} className={sortByAlpha? "rotate180": ""} alt="filter"/> */}
          </div>

          <div
            className="filter"
            style={{ transform: 'translateX(-70%)'}}
          >
            <span>Montant total</span>
          </div>

          <div
            style={{ transform: 'translateX(-50%)'}}
            className="filter">
            {/* onClick={() => setSortByStatus(sortByStatus)}> */}
            <span>status</span>
            {/* <img src={filterIcon} className={sortByStatus? "rotate180": ""} alt="filter"/> */}
          </div>

        </div>

        {renderOrdersList()}

      </main>

    </div>
  )
  // eslint-disable-next-line
};
