import React from 'react';
import { API_URL } from '../../../../actions/index';
import { Link } from "react-router-dom";

import { calcOfferPrice } from '../../../../utils/utils';
import { useGlobalContext } from '../../../context/GlobalContext';
import { putProductAction } from '../../../../actions/offersActions';

export default function OfferRow(props) {
  const { offer } = props;

  const dispatch = useGlobalContext()[1];

  function renderUpdatedLabel() {
    var backgroundColor = "#FF8900";
  
    if(offer.status === "valid") {
      backgroundColor = "#32A860";
    }
    if(offer.status === "correction") {
      backgroundColor = "#F4D300";
    }
    if(offer.status === "refused") {
      backgroundColor = "#FF0009";
    }
    return (
      <div className="updated-label" style={{ backgroundColor,   boxShadow: `0px 0px 2px 2px rgba(236, 162, 92, 0.0)` }}></div>
    );
  
  };

  async function handleValidation(e) {
    e.preventDefault();
    await putProductAction(dispatch, offer._id, { status: 'valid' })
  }

  function renderStatus() {
    var color = "#FF8900";
    var label = "Valider";
    if(offer?.status === "valid") {
      color = "#32A860";
      return <p style={{ color }}>Validée</p>
    }
    if(offer?.status === "correction") {
      color = "#F4D300";
      return <p style={{ color }}>En correction</p>
    }
    if(offer?.status === "refused") {
      color = "#FF0009";
      return <p style={{ color }}>Refusé</p>
    }
    return (
        <button
          type="button"
          onClick={(e) => handleValidation(e)}
        >
          {label}
        </button>
    );
  };

  return (
    <Link to={"/offer-view/" + offer._id}>

      <div className="list-row">

        {renderUpdatedLabel()}

        <div className="name">
          <div className="icon producoffer">
            {offer.image?.path && <img
              src={`${API_URL}/files/${offer.image?.path.replace('upload/', '')}`}
              alt="product"/>}
          </div>
          <p>
            {offer?.company?.name}
          </p>
        </div>

        <div className="info offer">

          <p>
            {offer.ref}
          </p>

          <p>
            {offer.gencod}
          </p>

          <p>
            {offer.packing}
          </p>

          <p className="price bold text-primary">
            {calcOfferPrice(offer)} €
          </p>

          <div className="status">
            {renderStatus()}
          </div>

        </div>

      </div>

    </Link>
  );
};
