/* eslint-disable react-hooks/exhaustive-deps */
import React , { useState , useCallback , useEffect } from 'react';
// import { Translate } from "react-localize-redux";
import * as _ from 'lodash';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Select from 'react-select';

import { exportOffersAction, getOffersFilteredAction, getOffersListAction } from '../../../actions/offersActions';
import { useGlobalContext } from '../../context/GlobalContext';
import history from "../../../utils/history";

import OffersRow from './Offers_Partials/OffersRow';

import searchIcon from "../../../assets/images/icons/search.svg";
import filterIcon from "../../../assets/images/icons/filters.svg";

import selectStyle from '../../Partials/selectStyle';

import '../../../styles/List.scss';
import { EXPORT_OFFERS, SET_OFFERS_FILTERS } from '../../../actions/types';
import ExportExcel from '../../Partials/ExportExcel';
import IconBtn from '../../Partials/IconBtn/IconBtn';

export default function OffersList({ setActivePage }) {
  const [ context, dispatch ] = useGlobalContext();
  const {  filters } = context.offers;
  const [ exportIsLoading , setExportIsLoading] = useState(null);

  const getOffersList = useCallback(() => {
    setActivePage("offers");
    getOffersListAction(dispatch);

    dispatch({
      type : 'GET_OFFER',
      payload : null
    });

    dispatch({
      type : 'SCROLL_ON_ANIMATIONS',
      payload : 0
    });

    dispatch({
      type : 'SCROLL_ON_PROVIDERS',
      payload : 0
    });
    if (context?.offers?.scrollOffers) {
      window.scrollTo(0, context.offers.scrollOffers)
    }

    dispatch({
      type : 'SCROLL_ON_SHOPS',
      payload : 0
    });

  }, [dispatch]);

  function handleChangeFilters(obj) {
    dispatch({
      type : SET_OFFERS_FILTERS,
      payload : { ...filters, ...obj}
    });
  }

  useEffect(() => {
    getOffersList();
  }, []);

  useEffect(() => {
    let fetch = null
    if (filters) {
      if (filters.search) {
        fetch = setTimeout(() => getOffersFilteredAction(dispatch, filters), 500);
      } else {
        getOffersFilteredAction(dispatch, filters)
      }
    }
    return () => clearTimeout(fetch);
  }, [filters])


  useScrollPosition(({ prevPos, currPos }) => {
    var scrollPosition = window.scrollY;
    var documentHeight = document.body.scrollHeight;
    var windowHeight = window.innerHeight;
    if (scrollPosition + windowHeight >= documentHeight) {
      handleChangeFilters({ ...filters, page: filters.page + 1}); 
    }
    dispatch({
      type : 'SCROLL_ON_OFFERS',
      payload : -currPos.y
    });
  });

  function renderOffersList() {
    if (context.offers.offersList) {
      if (context.offers.offersList.length > 0) {
        return context.offers.offersList.map(( offer , i) => <OffersRow key={i} offer={offer}/>);
      } else if (filters.search && context.offers.offersList.length === 0) {
        return <p className="text-error search-error">Aucun résultat ne correspond à votre recherche</p>;
      };
    };
  };

  function renderSelectProduct() {
    var options = []
    if (context.offers.lists.products) {
      context.offers.lists.products.forEach(item => {
        if (!options?.find((o) => o.value === item)) {
          options.push({
            label: item,
            value: item
          });
        }
      });
    }
    return (
      <Select
        options={_.uniqBy(options, "label")}
        value={options.filter((opt) => filters?.products?.find((o) => o === opt.value))}
        onChange={(val) => handleChangeFilters({
          page: 1,
          products: val?.map(v => v.value) || []
        })}
        isMulti={true}
        styles={selectStyle}
        placeholder="Filtrer par type de produits"
        />
    )
  };

  function renderExportBtn() {
    if (context.offers.exportList && context.offers?.exportList?.fileName) {
      return <ExportExcel
        dispatch={dispatch}
        fileName={context.offers.exportList.fileName}
        dataToExport={context.offers.exportList.dataToExport}
        callBack={() => {
          dispatch({
            type : EXPORT_OFFERS,
            payload : null
          });
          setExportIsLoading(false);
        }} 
        />
    } else {
      return <IconBtn
      label={"Exporter"}
      icon="export"
      isLoading={exportIsLoading}
      handleClick={() => {
        exportOffersAction(dispatch);
        setExportIsLoading(true);
      }}
      />
    }
  }


  return (
    <div className="page-container">
      <header className="page-header">
        <h1>Offres salon</h1>
      </header>

      <main className="page-content">

        <div className="row search-container">
          <div className="col-3 input">
            <input placeholder="Recherche"
              value={filters.search}
              onChange={(e) => handleChangeFilters({ search: e.target.value, page: 1 })}
            />
            <img className={'search-icon'} src={searchIcon} alt="search"/>
          </div>
          <div className="select-products">
            {renderSelectProduct()}
          </div>
          <div className="col-2"
            style={{ display: 'flex', justifyContent: "flex-end" , margin:0 }}
          >
            {renderExportBtn()}
            <button 
              className="btn-edit hide-tablet"
              style={{ height:36 , paddingLeft: 15 , paddingRight: 15 }}
              onClick={() => history.push('/offer-create')} 
            >
              Nouveau produit
            </button>
          </div>
        </div>

        <div className="filters">

          <div
            className="filter"
            style={{ minWidth: '15%'}}
            onClick={() => handleChangeFilters({
              page: 1,
              orderBy: {
                key: "name",
                order: filters.orderBy.order === "asc" ? "desc":"asc"
              }
            })}>
            <span>Nom du fournisseur</span>
            <img src={filterIcon} className={filters.orderBy.key === 'name' && filters.orderBy.order === "asc"? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translate(48%, 0)'}}
            onClick={() => handleChangeFilters({
              page: 1,
              orderBy: {
                key: "ref",
                order: filters.orderBy.order === "asc" ? "desc":"asc"
              }
            })}>
            <span>Référence</span>
            <img src={filterIcon} className={filters.orderBy.key === 'ref'  && filters.orderBy.order === "asc"? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translate(42%, 0)'}}
            onClick={() => handleChangeFilters({
              page: 1,
              orderBy: {
                key: "gencod",
                order:filters.orderBy.key === 'gencod' && filters.orderBy.order === "asc" ? "desc":"asc"
              }
            })}>
            <span>Gencod</span>
            <img src={filterIcon} className={filters.orderBy.order === "asc"? "rotate180": ""} alt="filter"/>
          </div>

          <div
            className="filter"
            style={{ transform: 'translate(18%, 0)'}}
          >
            <span>Qté en colis</span>
            {/* <img src={filterIcon} className={filters.orderBy.order === "asc"? "rotate180": ""} alt="filter"/> */}
          </div>

          <div
            className="filter"
            style={{ transform: 'translate(-8%, 0)'}}
          >
            <span>Montant total</span>
            {/* <img src={filterIcon} className={filters.orderBy.order === "asc" ? "rotate180": ""} alt="filter"/> */}
          </div>

          <div
            className="filter"
            style={{ transform: 'translate(-20%, 0)'}}
          >
            <span>Validation</span>
            {/* <img src={filterIcon} className={filters.orderBy.order === "asc"  ? "rotate180": ""} alt="filter"/> */}
          </div>

        </div>

        {renderOffersList()}

      </main>

    </div>
  );
};
